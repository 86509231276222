import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 45 min. `}<meta itemProp="prepTime" content="PT45M" /></h5>
    <h5>{`Cook time: 45 min. `}<meta itemProp="cookTime" content="PT45M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Kale, 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">2-4 cloves garlic</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Extra-virgin olive oil, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan cheese, 3/4 cup grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Whole wheat penne, 1 box (16 oz.)</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Canned tuna or salmon, 2 5 oz. cans</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Food processor</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Small frying pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Colander</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Pasta scoop</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Wash kale</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Peel garlic</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Drain tuna/salmon</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Boil kale leaves until tender, about 5 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While the kale is cooking, fry garlic in some of the olive oil until soft and light brown, about 5 minutes. Set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Don't toss the kale water - save it for the pasta. Add kale, garlic and its oil, remaining olive oil, 3/4 of the parmesan, salt, and pepper to the food processor. Blend until desired consistency.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook pasta in the kale water according to box instructions.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Return drained pasta to pot.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Over low heat, stir in the kale mixture.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in cayenne, remaining parmesan, and tuna/salmon.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      