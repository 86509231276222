import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 40 min. `}<meta itemProp="cookTime" content="PT40M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black beans, 2 15 oz. cans</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Onion, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 3 cloves</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chipotle peppers in adobo sauce, 2 pieces + 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mayonnaise, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Worchestershire sauce, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sriracha, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cumin, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chili powder, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Smoked paprika, 1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Egg, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Panko bread crumnbs, 1 1/4 cup</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Potato masher</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Food processor</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Peel and quarter onions</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Drain and mash beans in large bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Roughly process onion, garlic, and peppers. Add to large bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add adobo sauce, mayo, Worchestershire sauce, Sriracha, cumin, chili powder, paprika, black pepper, and egg to the large bowl and mix until even.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in bread crumbs until even.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Shape into 6 patties.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Chill for at least 10 minutes and freeze on parchment paper, if desired. Thaw for one hour before frying.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Fry on oiled pan for ~5 min. per side.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      