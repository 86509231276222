// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---recipes-bbq-brisket-mdx": () => import("./../../../recipes/bbq-brisket.mdx" /* webpackChunkName: "component---recipes-bbq-brisket-mdx" */),
  "component---recipes-beef-shanks-mdx": () => import("./../../../recipes/beef-shanks.mdx" /* webpackChunkName: "component---recipes-beef-shanks-mdx" */),
  "component---recipes-beer-bread-mdx": () => import("./../../../recipes/beer-bread.mdx" /* webpackChunkName: "component---recipes-beer-bread-mdx" */),
  "component---recipes-black-bean-burgers-mdx": () => import("./../../../recipes/black-bean-burgers.mdx" /* webpackChunkName: "component---recipes-black-bean-burgers-mdx" */),
  "component---recipes-bolognese-sauce-mdx": () => import("./../../../recipes/bolognese-sauce.mdx" /* webpackChunkName: "component---recipes-bolognese-sauce-mdx" */),
  "component---recipes-breaded-eggplant-chicken-mdx": () => import("./../../../recipes/breaded-eggplant-chicken.mdx" /* webpackChunkName: "component---recipes-breaded-eggplant-chicken-mdx" */),
  "component---recipes-bubur-ayam-mdx": () => import("./../../../recipes/bubur-ayam.mdx" /* webpackChunkName: "component---recipes-bubur-ayam-mdx" */),
  "component---recipes-burgers-mdx": () => import("./../../../recipes/burgers.mdx" /* webpackChunkName: "component---recipes-burgers-mdx" */),
  "component---recipes-burritos-mdx": () => import("./../../../recipes/burritos.mdx" /* webpackChunkName: "component---recipes-burritos-mdx" */),
  "component---recipes-carbonara-mdx": () => import("./../../../recipes/carbonara.mdx" /* webpackChunkName: "component---recipes-carbonara-mdx" */),
  "component---recipes-chicken-sate-w-peanut-sauce-mdx": () => import("./../../../recipes/chicken-sate-w-peanut-sauce.mdx" /* webpackChunkName: "component---recipes-chicken-sate-w-peanut-sauce-mdx" */),
  "component---recipes-clam-chowder-mdx": () => import("./../../../recipes/clam-chowder.mdx" /* webpackChunkName: "component---recipes-clam-chowder-mdx" */),
  "component---recipes-corn-on-the-cob-mdx": () => import("./../../../recipes/corn-on-the-cob.mdx" /* webpackChunkName: "component---recipes-corn-on-the-cob-mdx" */),
  "component---recipes-creamy-tomato-chicken-mdx": () => import("./../../../recipes/creamy-tomato-chicken.mdx" /* webpackChunkName: "component---recipes-creamy-tomato-chicken-mdx" */),
  "component---recipes-deviled-eggs-mdx": () => import("./../../../recipes/deviled-eggs.mdx" /* webpackChunkName: "component---recipes-deviled-eggs-mdx" */),
  "component---recipes-gochujang-noodles-mdx": () => import("./../../../recipes/gochujang-noodles.mdx" /* webpackChunkName: "component---recipes-gochujang-noodles-mdx" */),
  "component---recipes-green-bean-casserole-mdx": () => import("./../../../recipes/green-bean-casserole.mdx" /* webpackChunkName: "component---recipes-green-bean-casserole-mdx" */),
  "component---recipes-hummus-mdx": () => import("./../../../recipes/hummus.mdx" /* webpackChunkName: "component---recipes-hummus-mdx" */),
  "component---recipes-kale-pasta-mdx": () => import("./../../../recipes/kale-pasta.mdx" /* webpackChunkName: "component---recipes-kale-pasta-mdx" */),
  "component---recipes-leeks-mornay-mdx": () => import("./../../../recipes/leeks-mornay.mdx" /* webpackChunkName: "component---recipes-leeks-mornay-mdx" */),
  "component---recipes-lemon-shrimp-pasta-mdx": () => import("./../../../recipes/lemon-shrimp-pasta.mdx" /* webpackChunkName: "component---recipes-lemon-shrimp-pasta-mdx" */),
  "component---recipes-pancakes-mdx": () => import("./../../../recipes/pancakes.mdx" /* webpackChunkName: "component---recipes-pancakes-mdx" */),
  "component---recipes-parmesan-crusted-salmon-mdx": () => import("./../../../recipes/parmesan-crusted-salmon.mdx" /* webpackChunkName: "component---recipes-parmesan-crusted-salmon-mdx" */),
  "component---recipes-remoulade-mdx": () => import("./../../../recipes/remoulade.mdx" /* webpackChunkName: "component---recipes-remoulade-mdx" */),
  "component---recipes-risotto-mdx": () => import("./../../../recipes/risotto.mdx" /* webpackChunkName: "component---recipes-risotto-mdx" */),
  "component---recipes-roasted-chicken-by-cut-and-temp-mdx": () => import("./../../../recipes/roasted-chicken-by-cut-and-temp.mdx" /* webpackChunkName: "component---recipes-roasted-chicken-by-cut-and-temp-mdx" */),
  "component---recipes-roasted-eggplant-mdx": () => import("./../../../recipes/roasted-eggplant.mdx" /* webpackChunkName: "component---recipes-roasted-eggplant-mdx" */),
  "component---recipes-roasted-potatoes-by-temp-mdx": () => import("./../../../recipes/roasted-potatoes-by-temp.mdx" /* webpackChunkName: "component---recipes-roasted-potatoes-by-temp-mdx" */),
  "component---recipes-scones-mdx": () => import("./../../../recipes/scones.mdx" /* webpackChunkName: "component---recipes-scones-mdx" */),
  "component---recipes-seafood-cakes-mdx": () => import("./../../../recipes/seafood-cakes.mdx" /* webpackChunkName: "component---recipes-seafood-cakes-mdx" */),
  "component---recipes-spinach-artichoke-pasta-mdx": () => import("./../../../recipes/spinach-artichoke-pasta.mdx" /* webpackChunkName: "component---recipes-spinach-artichoke-pasta-mdx" */),
  "component---recipes-tomato-spinach-ricotta-pasta-mdx": () => import("./../../../recipes/tomato-spinach-ricotta-pasta.mdx" /* webpackChunkName: "component---recipes-tomato-spinach-ricotta-pasta-mdx" */),
  "component---recipes-turkey-chili-mdx": () => import("./../../../recipes/turkey-chili.mdx" /* webpackChunkName: "component---recipes-turkey-chili-mdx" */),
  "component---recipes-veronicas-cookies-mdx": () => import("./../../../recipes/veronicas-cookies.mdx" /* webpackChunkName: "component---recipes-veronicas-cookies-mdx" */),
  "component---recipes-whole-roasted-chicken-mdx": () => import("./../../../recipes/whole-roasted-chicken.mdx" /* webpackChunkName: "component---recipes-whole-roasted-chicken-mdx" */),
  "component---recipes-yellow-rice-mdx": () => import("./../../../recipes/yellow-rice.mdx" /* webpackChunkName: "component---recipes-yellow-rice-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

